<div class="dark">
    <nav class="navbar">
        <ul class="navbar-nav">
            <li class="logo">
                <a href="javascript:void(0)" class="nav-link">
                    <span class="link-text logo-text">RESUME</span>
                    <svg aria-hidden="true" focusable="false" data-prefix="fad" data-icon="angle-double-right"
                        role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"
                        class="svg-inline--fa fa-angle-double-right fa-w-14 fa-5x">
                        <g class="fa-group">
                            <path fill="currentColor"
                                d="M224 273L88.37 409a23.78 23.78 0 0 1-33.8 0L32 386.36a23.94 23.94 0 0 1 0-33.89l96.13-96.37L32 159.73a23.94 23.94 0 0 1 0-33.89l22.44-22.79a23.78 23.78 0 0 1 33.8 0L223.88 239a23.94 23.94 0 0 1 .1 34z"
                                class="fa-secondary"></path>
                            <path fill="currentColor"
                                d="M415.89 273L280.34 409a23.77 23.77 0 0 1-33.79 0L224 386.26a23.94 23.94 0 0 1 0-33.89L320.11 256l-96-96.47a23.94 23.94 0 0 1 0-33.89l22.52-22.59a23.77 23.77 0 0 1 33.79 0L416 239a24 24 0 0 1-.11 34z"
                                class="fa-primary"></path>
                        </g>
                    </svg>
                </a>
            </li>
            <li class="nav-item">
                <a href="javascript:void(0)" class="nav-link">
                    <svg aria-hidden="true" focusable="false" data-prefix="fad" data-icon="user-secret" role="img"
                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"
                        class="svg-inline--fa fa-user-secret fa-w-14 fa-3x">
                        <g class="fa-group">
                            <path fill="currentColor"
                                d="M255.38 421.22L224 480l-31.38-58.78L208 352l-17.79-35.58a161.25 161.25 0 0 0 67.58 0L240 352zM224 288a128 128 0 0 0 128-127.21c-7.49 1.54-15.51 3-24 4.2v6.59c-.11.11-6.07 3.47-6.93 6.28-4.23 12.9-7.59 26.65-17.88 36.19-10.94 10.07-52 24.26-69.33-27.09-3-9.1-16.69-9.1-19.83 0-18.41 54.39-60.66 35.1-69.33 27.09-10.29-9.54-13.76-23.29-17.88-36.19-.86-2.7-6.82-6.17-6.82-6.28V165c-8.48-1.25-16.5-2.66-24-4.2A128 128 0 0 0 224 288z"
                                class="fa-secondary"></path>
                            <path fill="currentColor"
                                d="M120 165v6.59c0 .11 6 3.58 6.82 6.28 4.12 12.9 7.59 26.65 17.88 36.19 8.67 8 50.92 27.3 69.33-27.09 3.14-9.1 16.79-9.1 19.83 0 17.33 51.35 58.39 37.16 69.33 27.09 10.29-9.54 13.65-23.29 17.88-36.19.86-2.81 6.82-6.17 6.93-6.28V165c52.95-7.83 88-21.47 88-37 0-13.75-27.51-26-70.6-34.09-9.35-32.11-26.69-64.08-40-80.72a32.1 32.1 0 0 0-39.5-8.8l-27.6 13.8a32 32 0 0 1-28.6 0l-27.6-13.8a32.1 32.1 0 0 0-39.5 8.8c-13.22 16.64-30.6 48.61-40 80.72C59.51 102 32 114.25 32 128c0 15.52 35.05 29.16 88 37zm263.9 143.27l23.9-62.58a16 16 0 0 0-15-21.7h-32.12L224 480 87.32 224h-31a16 16 0 0 0-14.7 22.3l25.74 60.06A133.56 133.56 0 0 0 0 422.4V464a48 48 0 0 0 48 48h352a48 48 0 0 0 48-48v-41.6a133.5 133.5 0 0 0-64.1-114.13z"
                                class="fa-primary"></path>
                        </g>
                    </svg>
                    <span class="link-text menu-item">About</span>
                </a>
            </li>
            <li class="nav-item">
                <a href="javascript:void(0)" class="nav-link">
                    <svg aria-hidden="true" focusable="false" data-prefix="fad" data-icon="laptop-code" role="img"
                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"
                        class="svg-inline--fa fa-laptop-code fa-w-20 fa-3x">
                        <g class="fa-group">
                            <path fill="currentColor"
                                d="M528 0H112a48.14 48.14 0 0 0-48 48v336h512V48a48.14 48.14 0 0 0-48-48zm-16 320H128V64h384z"
                                class="fa-secondary"></path>
                            <path fill="currentColor"
                                d="M624 416H381.54c-.74 19.81-14.71 32-32.74 32H288c-18.69 0-33-17.47-32.77-32H16a16 16 0 0 0-16 16v16a64.19 64.19 0 0 0 64 64h512a64.19 64.19 0 0 0 64-64v-16a16 16 0 0 0-16-16zM512 64H128v256h384zM289 250.34l-11.31 11.31a16 16 0 0 1-22.63 0l-58.35-58.34a16 16 0 0 1 0-22.63L255 122.34a16 16 0 0 1 22.63 0L289 133.65a16 16 0 0 1 0 22.63L253.25 192 289 227.71a16 16 0 0 1 0 22.63zm154.35-47L385 261.66a16 16 0 0 1-22.63 0L351 250.35a16 16 0 0 1 0-22.63L386.75 192 351 156.29a16 16 0 0 1 0-22.63l11.31-11.31a16 16 0 0 1 22.63 0l58.34 58.34a16 16 0 0 1 .04 22.63z"
                                class="fa-primary"></path>
                        </g>
                    </svg>
                    <span class="link-text menu-item">Skills</span>
                </a>
            </li>
            <li class="nav-item">
                <a href="javascript:void(0)" class="nav-link">
                    <svg aria-hidden="true" focusable="false" data-prefix="fad" data-icon="code-branch" role="img"
                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"
                        class="svg-inline--fa fa-code-branch fa-w-12 fa-3x">
                        <g class="fa-group">
                            <path fill="currentColor"
                                d="M328 220.33V224c0 32-6.69 47.26-20 63.8-28.2 35-76 39.5-118.2 43.4-25.7 2.4-49.9 4.6-66.1 12.8-3.82 1.94-9.25 6.44-13.44 13.94A80.16 80.16 0 0 0 56 355.67V156.33a80.31 80.31 0 0 0 48 0v144c23.9-11.5 53.1-14.3 81.3-16.9 35.9-3.3 69.8-6.5 85.2-25.7 6.34-7.83 9.5-17.7 9.5-33.7v-3.67a80.31 80.31 0 0 0 48 0z"
                                class="fa-secondary"></path>
                            <path fill="currentColor"
                                d="M80 0a80 80 0 1 0 80 80A80 80 0 0 0 80 0zm0 96a16 16 0 1 1 16-16 16 16 0 0 1-16 16zm0 256a80 80 0 1 0 80 80 80 80 0 0 0-80-80zm0 96a16 16 0 1 1 16-16 16 16 0 0 1-16 16zM304 64a80 80 0 1 0 80 80 80 80 0 0 0-80-80zm0 96a16 16 0 1 1 16-16 16 16 0 0 1-16 16z"
                                class="fa-primary"></path>
                        </g>
                    </svg>
                    <span class="link-text menu-item">Experience</span>
                </a>
            </li>
            <li class="nav-item">
                <a href="javascript:void(0)" class="nav-link">
                    <svg aria-hidden="true" focusable="false" data-prefix="fad" data-icon="file-certificate" role="img"
                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
                        class="svg-inline--fa fa-file-certificate fa-w-16 fa-3x">
                        <g class="fa-group">
                            <path fill="currentColor"
                                d="M512 128v360.12A23.94 23.94 0 0 1 488 512H224v-91.19a61.78 61.78 0 0 0 35.49-41.11c.38-1.42.72-2.72 1-3.94.66-2.56 1.4-5.39 1.89-7 1.14-1.23 3.12-3.24 4.92-5.06l2.91-2.94A62.5 62.5 0 0 0 286 301.38c-.37-1.41-.72-2.71-1-3.92-.69-2.61-1.46-5.5-1.84-7.16.38-1.68 1.16-4.6 1.86-7.25.31-1.18.65-2.45 1-3.83a62.45 62.45 0 0 0-15.63-59.28l-2.76-2.81c-1.85-1.88-3.9-3.95-5.05-5.2-.49-1.64-1.23-4.51-1.91-7.1q-.47-1.8-1-3.9a61.69 61.69 0 0 0-43.13-43.7h-.11l-3.75-1c-2.44-.66-5.13-1.39-6.75-1.88-1.23-1.18-3.22-3.21-5-5.05-.84-.86-1.73-1.78-2.71-2.77a60.88 60.88 0 0 0-59.47-16.31h-.05l-3.77 1c-2.4.66-5 1.39-6.69 1.79V23.88A23.94 23.94 0 0 1 152 0h232v112a16 16 0 0 0 16 16z"
                                class="fa-secondary"></path>
                            <path fill="currentColor"
                                d="M505 105L407.1 7a24 24 0 0 0-17-7H384v112a16 16 0 0 0 16 16h112v-6.1a23.9 23.9 0 0 0-7-16.9zM255 271.09a30.14 30.14 0 0 0-7.58-28.79c-14.86-15.12-13.42-12.61-18.86-33.3a29.57 29.57 0 0 0-20.71-21c-20.28-5.53-17.84-4.1-32.69-19.21a28.92 28.92 0 0 0-28.28-7.79c-20.32 5.54-17.46 5.53-37.75 0a28.94 28.94 0 0 0-28.28 7.71c-14.91 15.18-12.5 13.7-32.68 19.21A29.53 29.53 0 0 0 27.46 209c-5.46 20.74-4 18.13-18.87 33.27A30.15 30.15 0 0 0 1 271.09c5.45 20.71 5.42 17.79 0 38.41a30.12 30.12 0 0 0 7.58 28.78c14.86 15.11 13.42 12.61 18.88 33.27a29.52 29.52 0 0 0 20.71 21.07c14.3 3.9 11.52 3 15.83 5V512l64-32 64 32V397.63c4.31-2 1.52-1.1 15.84-5a29.53 29.53 0 0 0 20.7-21.07c5.47-20.74 4-18.13 18.88-33.27a30.12 30.12 0 0 0 7.58-28.78c-5.43-20.65-5.44-17.74 0-38.42zM128 352a64 64 0 1 1 64-64 64 64 0 0 1-64 64z"
                                class="fa-primary"></path>
                        </g>
                    </svg>
                    <span class="link-text menu-item">Education</span>
                </a>
            </li>
            <li class="nav-item" id="themeButton">
                <a href="javascript:void(0)" class="nav-link">
                    <svg aria-hidden="true" focusable="false" data-prefix="fad" data-icon="id-card" role="img"
                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"
                        class="svg-inline--fa fa-id-card fa-w-18 fa-3x">
                        <g class="fa-group">
                            <path fill="currentColor"
                                d="M0 128v304a48 48 0 0 0 48 48h480a48 48 0 0 0 48-48V128zm176 64a64 64 0 1 1-64 64 64 64 0 0 1 64-64zm93.3 224H82.7c-10.4 0-18.8-10-15.6-19.8A64.09 64.09 0 0 1 128 352h8.2a103 103 0 0 0 79.6 0h8.2a64.09 64.09 0 0 1 60.9 44.2c3.2 9.9-5.2 19.8-15.6 19.8zM512 344a8 8 0 0 1-8 8H360a8 8 0 0 1-8-8v-16a8 8 0 0 1 8-8h144a8 8 0 0 1 8 8zm0-64a8 8 0 0 1-8 8H360a8 8 0 0 1-8-8v-16a8 8 0 0 1 8-8h144a8 8 0 0 1 8 8zm0-64a8 8 0 0 1-8 8H360a8 8 0 0 1-8-8v-16a8 8 0 0 1 8-8h144a8 8 0 0 1 8 8z"
                                class="fa-secondary"></path>
                            <path fill="currentColor"
                                d="M224 352h-8.2a103 103 0 0 1-79.6 0H128a64.09 64.09 0 0 0-60.9 44.2C63.9 406 72.3 416 82.7 416h186.6c10.4 0 18.8-9.9 15.6-19.8A64.09 64.09 0 0 0 224 352zM528 32H48A48 48 0 0 0 0 80v48h576V80a48 48 0 0 0-48-48zM176 320a64 64 0 1 0-64-64 64 64 0 0 0 64 64z"
                                class="fa-primary"></path>
                        </g>
                    </svg>
                    <span class="link-text menu-item">Contact</span>
                </a>
            </li>
        </ul>
    </nav>
    <main>
        <router-outlet></router-outlet>
    </main>
</div>